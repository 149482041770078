import axios from '@/plugins/axios'

/**
 * Activate advertiser
 */
const activateAdvertiser = async id => {
  try {
    const response = await axios.post(`advertisers/${id}/activate`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Add coupon to advertiser
 */
const addCouponToAdvertiser = async ({
  id,
  code
}) => {
  try {
    const response = await axios.post(`advertisers/${id}/coupon`, {
      code: code
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Create advertiser
 */
const createAdvertiser = async ({
  address = null,
  country = null,
  name = null,
  // eslint-disable-next-line camelcase
  advertiser_document = null,
  // eslint-disable-next-line camelcase
  billing_profile = null,
  // eslint-disable-next-line camelcase
  currency = null,
  website = null,
  // eslint-disable-next-line camelcase
  category_id = null,
  logo = null
}) => {
  try {
    const form = new FormData()
    if (address) {
      Object.keys(address).forEach(key => {
        form.append(`address[${key}]`, address[key])
      })
    }
    form.append('country', country)
    form.append('name', name)
    form.append('billing_profile', billing_profile)
    form.append('currency', currency)
    form.append('advertiser_document', advertiser_document)
    form.append('website', website)
    form.append('category_id', category_id)
    if (logo) form.append('logo', logo)
    const response = await axios.post('advertisers', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Destroy advertiser
 */
const destroyAdvertiser = async id => {
  try {
    const response = await axios.post(`advertisers/${id}`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get single advertiser
 */
const getAdvertiser = async id => {
  try {
    const response = await axios.get(`advertisers/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get advertisers
 */
const getAdvertisers = async ({
  // eslint-disable-next-line camelcase
  category_id = null,
  companyId,
  name = null,
  status,
  page = 1,
  itemsPerPage = 9
}) => {
  try {
    const params = {
      company_id: companyId
    }
    // eslint-disable-next-line camelcase
    if (category_id) params.category_id = category_id
    if (name) params.name = name
    if (status) params.status = status
    params.page = page
    params.items_per_page = itemsPerPage
    const response = await axios.get('advertisers', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Remove coupon from advertiser
 */
const removeActiveCouponFromAdvertiser = async (id) => {
  try {
    const response = await axios.post(`advertisers/${id}/coupon`, {
      _method: 'DELETE'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`advertisers/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update advertiser
 */
const updateAdvertiser = async (id, {
  address = null,
  country = null,
  name = null,
  // eslint-disable-next-line camelcase
  advertiser_document = null,
  // eslint-disable-next-line camelcase
  billing_profile = null,
  website = null,
  // eslint-disable-next-line camelcase
  category_id = null,
  logo = null
}) => {
  try {
    const form = new FormData()
    if (address) {
      Object.keys(address).forEach(key => {
        form.append(`address[${key}]`, address[key])
      })
    }
    form.append('country', country)
    form.append('name', name)
    form.append('advertiser_document', advertiser_document)
    form.append('billing_profile', billing_profile)
    form.append('website', website)
    form.append('category_id', category_id)
    if (logo) form.append('logo', logo)
    form.append('_method', 'PUT')
    const response = await axios.post(`advertisers/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  activateAdvertiser,
  addCouponToAdvertiser,
  createAdvertiser,
  destroyAdvertiser,
  getAdvertiser,
  getAdvertisers,
  removeActiveCouponFromAdvertiser,
  retryCreationOnPlatform,
  updateAdvertiser
}
