<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('campaigns') }}</span>
        </h1>
      </div>
      <div class="d-flex">
        <CreateCampaignButton />
        <div class="ms-3">
          <router-link
            class="btn btn-primary"
            :to="{ name: 'campaigns.import' }"
            v-b-tooltip.hover :title="$t('import_existing_campaigns')"
            v-if="1 == 2"
          >
            {{ $t('import_campaign') }}
        </router-link>
        </div>
      </div>
    </div>
    <!-- CAMPAIGNS -->
    <div class="mt-4">
      <template v-if="user">
        <CampaignListTable />
      </template>
    </div> <!-- END CAMPAIGNS -->
  </div>
</template>

<script>

import { mapState } from 'vuex'

import CampaignListTable from '@/components/campaigns/CampaignListTable'
import CreateCampaignButton from '@/components/campaigns/CreateCampaignButton'

export default {
  components: {
    // Button,
    CampaignListTable,
    CreateCampaignButton
  },
  data () {
    return {}
  },
  metaInfo () {
    return {
      title: this.$t('campaigns')
    }
  },
  created () {
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  }
}
</script>
